import {
    _urlBadges,
    _urlBanks, _urlCallRequest,
    _urlCustomPages,
    _urlLanguage,
    _urlMenu, _urlPartners,
    _urlProjects, _urlSettings,
    _urlStaticTexts,
    request
} from "../api";
import { GENERAL_CONSTS, UTIL_CONSTS } from "../constants";

export const GetSettings = () => {
    const requestData = {
        url: `${_urlSettings}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_SETTINGS,
                    payload: data
                })
            })
    };
};

export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetStaticTexts = () => {
    const requestData = {
        url: `${_urlStaticTexts}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const GetMenu = () => {
    const requestData = {
        url: `${_urlMenu}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_MENU,
                    payload: data
                })
            })
    };
};
export const GetBadges = () => {
    const requestData = {
        url: `${_urlBadges}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_BADGES,
                    payload: data
                })
            })
    };
};

export const GetCustomPages = (pageSLug) => {
    const requestData = {
        url: `${_urlCustomPages}/${pageSLug}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CUSTOM_PAGES,
                    payload: data
                })
            })
    };
};

export const GetImages = () => {
    const requestData = {
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const GetBanks = () => {
    const requestData = {
        url: `${_urlBanks}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_BANKS,
                    payload: data
                })
            })
    };
};

/*-------------Projects----------------*/
export const GetProjects = () => {
    const requestData = {
        url:`${_urlProjects}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_PROJECTS,
                    payload: data
                })
            })
    };
};

export const GetProjectsById = (id) => {
    const requestData = {
        url: `${_urlProjects}/${id}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_PROJECT_BY_ID,
                    payload: data
                })
            })
    };
};

export const ClearProjectById = () => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CLEAR_PROJECTS_BY_ID,
        })
    };
}


export const SendCallRequest = (data) => {
    const requestData = {
        url: `${_urlCallRequest}`,
        method: "POST",
        data
    };
    return dispatch => {
        dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData).finally(() => {
            dispatch({ type: UTIL_CONSTS.END_LOADING })
        })
    }
};

export const GetPartners = () => {
    const requestData = {
        url:`${_urlPartners}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async(res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_PARTNERS,
                    payload: data
                })
            })
    };
};
