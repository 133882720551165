// import packages
import React, { Component, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Link } from "react-router-dom";

//Import styles
import "./homeSlider.scss";

//Import Components
import HomeFilter from "./filter/HomeFilter";
import {
    ArrowIcon, ArrUpIcon, MobileCalcIcon, MobileFilterIcon,
    MobileLeftArrIcon, MobileMapIcon, MobileRightArrIcon, SliderArrIcon
} from "../../../assets/images";

// Import utils
import { generateImageMediaUrl, generateSliderMediaUrl } from "../../../utils/generateMediaUrl";
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { getUrlWithLocal } from "../../../utils/getUrlWithLocal";
import ProgressiveImage from "react-progressive-graceful-image";
import { resizeImage } from "../../../utils/resizeImage";
import { PrimaryButton } from "../../../components/ui-elements/buttons/Buttons";
import MapWindow from "../../filtersPage/mapWindow/MapWindow";
import { toggleMapWindow } from "../../../utils/toggleMapWindow";
import SwiperPagination from "../../../components/swiper-pagination/SwiperPagination";

const defaultDuration = 5; //seconds
function HomeSlider (props) {
    const { mainSlider, staticTexts, projects, areaData, settings } = props;
    const [slideIndex, setSlideIndex] = useState(0);
    const [sliderCircleEnded, setSliderCircleEnded] = useState(false);

    // const [currentSlideDurationMs, setCurrentSlideDurationMs] = useState(null);
    const sliderRef = useRef(null);
    const showSliderProjects = settings?.showSliderProjects

    let sliders = []
    let singleVideo=false;
    if (showSliderProjects) {
        sliders = mainSlider && Array.isArray(mainSlider) ? mainSlider.filter(s => s.project) : [];
    } else {
        sliders = mainSlider && Array.isArray(mainSlider) ? mainSlider.filter(s => !s.project) : [];
        if(sliders?.length === 1 && sliders[0]?.mediaVideo){
            singleVideo =true
        }
    }
    // useEffect(() => {
    //     console.log('sliders', sliders)
    //     if (!showSliderProjects && !currentSlideDurationMs && sliders && Array.isArray(mainSlider) && sliders.length) {
    //         console.log('sliders', sliders)
    //         setSliderCircleEnded(false)
    //         const firstSlider = sliders[0];
    //         if (firstSlider?.mediaVideo) {
    //             const slide = document.getElementById('slider_' + slideIndex);
    //             console.log('effect', !!slide, slide?.duration)
    //             const duration = slide?.duration || defaultDuration
    //             setCurrentSlideDurationMs(duration)
    //             setTimeout(()=>{
    //                 sliderRef.current.slickNext();
    //             }, duration)
    //         }
    //     }
    // }, [mainSlider]);

    function handleSliderChange () {

    }

    function nextSlide (index) {
        setSlideIndex(index + 1)
        sliderRef.current.slickNext();
    }

    function prevSlide (index) {
        setSlideIndex(index - 1)
        sliderRef.current.slickPrev();
    }

    function slickGoTo (index) {
        setSlideIndex(index)
        sliderRef.current.slickGoTo(index);
    }

    function afterChangeSlideIndex (slideIndex) {
        const slide = document.getElementById('slider_' + slideIndex);
        // console.log('afterChangeSlideIndex', slideIndex, !!slide)
        slide?.play?.()
        setSliderCircleEnded(false)
    }

    function beforeChangeSlideIndex (prevSlide, slideIndex) {
        const slide = document.getElementById('slider_' + prevSlide);
        // console.log('beforeChangeSlideIndex', prevSlide, slideIndex, !!slide)
        setSliderCircleEnded(true)
        setSlideIndex(slideIndex)
    }


    const sliderSettings = {
        dots: false,
        arrows: false,
        autoplay: true, //!!showSliderProjects,
        pauseOnHover: false,
        swipeToSlide: false,
        autoplaySpeed: 5000,
        speed: 400,
        swipe: true,
        infinite: showSliderProjects?  true : !singleVideo,
        ref: slider => (sliderRef.current = slider),
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: afterChangeSlideIndex,
        beforeChange: beforeChangeSlideIndex,
        fade: true,
    };

    console.log(sliders,'sliders')
    console.log(projects,'projects')

    return  <div className="home-image-wrapper " ref={props.refProp}>
        <div className={'home-image-block'}>
            {<div className={'pagination_wrapper'}>
                <SwiperPagination slider={sliders}
                                  slickGoTo={slickGoTo}
                                  showOnDesktop={!showSliderProjects}
                                  slideIndex={slideIndex}
                                  sliderCircleEnded={sliderCircleEnded}
                                  sliderLenght={sliders?.length}/>
            </div>}
            <div className={'main-slider-items'}>
                <div className={'main-slider-inner-part'}>
                    <Slider {...sliderSettings}>
                        {sliders?.length && sliders?.map((item, index) => {

                            return <div key={item.id} className={`slider-item`}>
                                {showSliderProjects && <div className="overlay_blur"/>}
                                {!showSliderProjects && <div className={`overlay`}>
                                    <div className={'overlay-inner'}>
                                        <div className={'overlay-title'}>
                                            {item?.title || ""}
                                        </div>
                                        <div className={'overlay-description'}>
                                            {item?.description || ""}
                                        </div>
                                        {item?.url &&
                                            <a href={item?.url} target={'_blank'} className={`slider_link_button`}>
                                                <SliderArrIcon/>
                                            </a>}
                                    </div>
                                </div>}
                                <Link to={getUrlWithLocal(`/buildings/${item?.project?.slug}`)}>
                                    {!showSliderProjects && item?.mediaVideo ? <div className={"video-wrapper"}>
                                            <video
                                                id={'slider_' + index}
                                                // ref={index === slideIndex ? activeSliderRef : null}
                                                // poster={item?.mediaMain?.path? generateSliderMediaUrl(item?.mediaMain?.path): null}
                                                src={generateSliderMediaUrl(item?.mediaVideo?.path)}
                                                onLoadedMetadata={!index ? (x) => {
                                                    // console.log('loaded', x)
                                                } : undefined}
                                                autoPlay
                                                muted
                                                loop={singleVideo}
                                                playsInline
                                            />
                                        </div> :
                                        (item?.mediaMain?.path ?
                                            <ProgressiveImage src={generateSliderMediaUrl(item?.mediaMain?.path)}
                                                              placeholder={resizeImage(generateImageMediaUrl(item?.mediaMain?.path))}>
                                                {(src, loading) => (
                                                    <img
                                                        className={`image${loading ? " loading" : " loaded"}`}
                                                        src={src}
                                                        alt=""
                                                    />
                                                )}
                                            </ProgressiveImage> : '')}
                                </Link>
                                {/*<div className={'mobile-block'}>
                                        <div className={'blocks-title'}>
                                            {item?.project?.title}
                                        </div>
                                        <Link to={getUrlWithLocal(`/buildings/${item?.project?.slug}`)}
                                              className={'about_block'}>
                                            {staticTexts?.homepage_mobile_address_button}
                                        </Link>
                                        <div className={'address_block'} onClick={toggleMapWindow}>
                                            <MobileMapIcon/> {item?.project?.address}
                                        </div>
                                        <div className={'second-block'}>
                                            <div className={'icon-block'}>
                                                <Link to={
                                                    {
                                                        // state: {statuses:['600ab6e5e503271f1c8ebcd0']},
                                                        pathname: getUrlWithLocal("/filter")
                                                    }
                                                } className="icon_wrapper">
                                                    <MobileFilterIcon/>
                                                </Link>
                                                <Link to={getUrlWithLocal("/calculator")} className="calc-icon_wrapper">
                                                    <MobileCalcIcon/>
                                                </Link>
                                            </div>
                                            <PrimaryButton link={getUrlWithLocal("/filter")}
                                                           title={staticTexts?.homepage_slider_button_houses.replace
                                                           ('%d', areaData?.availableCount || 0)}/>
                                        </div>
                                        {
                                            <div className={'buttons_block'}>
                                                <button className={`slider-btn  prev-btn`}
                                                        onClick={() => prevSlide(slideIndex)}
                                                >
                                                    <MobileLeftArrIcon/>
                                                </button>
                                                <button
                                                    className={`slider-btn next-btn`}
                                                    onClick={() => nextSlide(slideIndex)}>
                                                    <MobileRightArrIcon/>
                                                </button>
                                            </div>
                                        }
                                    </div>*/}
                            </div>
                        })}
                    </Slider>
                    {
                        showSliderProjects && !!projects?.length  ? <div className={'slider_prev_btn'} onClick={prevSlide}>
                            <ArrUpIcon/>
                        </div> : ''
                    }
                    {showSliderProjects && !!projects?.length && <div className="projects-wrapper">
                    {projects.map((item) => {
                            return <Link to={getUrlWithLocal(`/buildings/${item?.slug}`)} className="project-item"
                                         key={item?.id}>
                                <div className="project-item__inner">
                                    {item?.mediaMain?.path &&
                                        <ProgressiveImage src={generateSliderMediaUrl(item?.mediaMain?.path)}
                                                          placeholder={resizeImage(generateImageMediaUrl(item?.mediaMain?.path))}>
                                            {(src, loading) => (
                                                <img
                                                    className={`image${loading ? " loading" : " loaded"}`}
                                                    src={src}
                                                    alt=""
                                                    width="400"
                                                    height="320"
                                                />
                                            )}
                                        </ProgressiveImage>}
                                    {/*<img src={generateSliderMediaUrl(item?.mediaMain?.path)}/>*/}
                                    <div className="project-item-info"/>
                                    <div className="content">
                                        <div className="title">{item?.title}</div>
                                        <div className={'project_link_wrapper'}>
                                            <Link to={getUrlWithLocal(`/buildings/${item?.slug}`)} target={'_blank'}
                                                  className={'slider_link_button project_button'}>
                                                <SliderArrIcon/>
                                            </Link>
                                        </div>
                                        {/*<div className="more">{staticTexts?.homepage_slider_project_view}*/}
                                        {/*    <ArrowIcon/></div>*/}
                                    </div>
                                </div>
                            </Link>
                        })}

                    </div>}
                    {
                        showSliderProjects && !!projects?.length ? <div className={'slider_next_btn'} onClick={nextSlide}>
                            <ArrUpIcon/>
                        </div> : ''
                    }

                    <div className={`slider_mobile_wrapper ${!showSliderProjects ? 'small_size' : ""}`}>
                        <SwiperPagination slider={sliders}
                                          slickGoTo={slickGoTo}
                                          showOnDesktop={!showSliderProjects}
                                          slideIndex={slideIndex}
                                          sliderCircleEnded={sliderCircleEnded}
                                          sliderLenght={sliders?.length}/>
                        {showSliderProjects && <>
                            <div className={'mobile_title'}>
                                {sliders?.[slideIndex]?.title}
                            </div>
                            <div className={'mobile_address'} onClick={toggleMapWindow}>
                                {sliders?.[slideIndex]?.project?.address}
                            </div>
                        </>}
                    </div>
                </div>
                <HomeFilter
                    searchBoxIsSticky={props.searchBoxIsSticky}
                    stickyRowIsOpen={props.stickyRowIsOpen}
                    toggleStickyRow={props.toggleStickyRow}
                />
                <MapWindow projects={projects}/>
            </div>
        </div>
    </div>

}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'settings',
        'staticTexts',
        'mainSlider',
        'contact',
        'projects',
        'areaData',
    ])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider);
