import './swiper-pagination.scss'
import {useEffect, useRef, useState} from "react";


export default function SwiperPagination(props) {
    const {slider,slideIndex, showOnDesktop, slickGoTo} = props
    const progressContent = useRef(null);
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        if(props.sliderCircleEnded ){
            setAnimate(false);
        }else{
            setAnimate(true);
        }
    }, [props.sliderCircleEnded ]);


    return <div className={`progress-wrapper ${showOnDesktop? 'show-on-desktop':''}`}>
        {
            slider?.length > 1 && slider.map((item,index) => {
                return <div className={'progress-item'}
                            onClick={()=>{slickGoTo(index)}}
                            style={{width: `calc(100% / ${slider?.length} - 5px`}}>
                    {
                        index === slideIndex ?
                            <span className={`stroke ${animate ? 'animate' : ''}`} ref={progressContent}></span> : ''
                    }
                </div>
            })
        }
    </div>
}
