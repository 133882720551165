// import packages
import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

// Import assets
import "./about.scss"
import 'video-react/dist/video-react.css';
import {
    AboutCheckedIcon,
    ArrowIcon,
    ArrowLeftIcon,
    ArrowRightIcon, PlayerCloseIcon,
    PlayBtnIcon, SliderArrIcon,
    SliderArrowIcon
} from "../../../assets/images";

// Import Components
import { PrimaryButton } from "../../../components/ui-elements/buttons/Buttons";
import { Player, ControlBar, PlayToggle, LoadingSpinner } from 'video-react';
// Import utils
import { getPropsFromState } from "../../../redux/mapStateToProps";
import {generateFileMediaUrl, generateSliderMediaUrl} from "../../../utils/generateMediaUrl";
import { getUrlWithLocal } from "../../../utils/getUrlWithLocal";
import Project from "./project/Project";
import VisibilitySensor from "react-visibility-sensor";
import { CountUp } from "use-count-up";
import MapWindow from "../../filtersPage/mapWindow/MapWindow";


function About (props) {
    const { aboutSlider, about, staticTexts, projects, contact, } = props
    const [playerOpen, setPlayerOpen] = useState(false)
    const artGroupProject = [
        ...projects,
        {
            latitude: contact?.location?.latitude,
            longitude: contact?.location?.longitude,
            title: contact?.address
        }]

    function togglePlayer () {
        const newState = !playerOpen
        setPlayerOpen(newState);

    }

    return <div className={'HomepageAbout'}>
        <span className="blur-circle"/>
        <span className="blur-circle right"/>
        <h1 className={'HomepageAboutTitle'}>{about?.pageName}</h1>
        <div className={'HomepageAboutContent'}>
            <div className="main-img-wrapper">
                <div className="main-img-content">
                    {aboutSlider?.[0]?.mediaMain?.path && <img src={generateFileMediaUrl(aboutSlider?.[0]?.mediaMain?.path)}
                          className={'main-img'} alt={"about"}/>}
                    {about?.mediaVideo?.path && <div className={'play-btn'} onClick={togglePlayer}>
                        <PlayBtnIcon/>
                    </div>}
                    <Link to={getUrlWithLocal('/about')} className="about-box">
                        <div className="about-box__title">{staticTexts?.homepage_about_box_title}</div>
                        <div className="about-box__description">
                            {staticTexts?.homepage_about_box_description}
                        </div>
                        <SliderArrIcon/>
                    </Link>
                </div>

            </div>
            <div className="FeaturesWrapper">
                <h2 className={'features-title'}>
                    {staticTexts?.homepage_about_features_title}
                </h2>

                <ul>
                    <li><AboutCheckedIcon/> {staticTexts?.homepage_about_feature_1}</li>
                    <li><AboutCheckedIcon/> {staticTexts?.homepage_about_feature_2}</li>
                    <li><AboutCheckedIcon/>{staticTexts?.homepage_about_feature_3}</li>
                </ul>


                <div className="counts">
                    <div className="counts__item">
                        <VisibilitySensor>{({ isVisible }) => {
                            return (
                                <div className="counts__item__count">
                                    <CountUp isCounting={isVisible} start={0}
                                             end={Number(`${staticTexts?.about_building_number}`)}
                                             duration={1} delay={4} easing="linear"/>
                                    +
                                </div>
                            );
                        }}</VisibilitySensor>

                        <small>{staticTexts?.homepage_about_projects_count}</small>
                    </div>
                    <div className="counts__item">
                        <VisibilitySensor>{({ isVisible }) => {
                            return (
                                <div className="counts__item__count">
                                    <CountUp isCounting={isVisible} start={0}
                                             end={Number(`${staticTexts?.about_partners_number}`)}
                                             duration={1} delay={4} easing="linear"/>
                                    +
                                </div>
                            );
                        }}</VisibilitySensor>

                        <small>{staticTexts?.homepage_about_years_count}</small>
                    </div>
                    <div className="counts__item">
                        <VisibilitySensor>{({ isVisible }) => {
                            return (
                                <div className="counts__item__count">
                                    <CountUp isCounting={isVisible} start={0}
                                             end={Number(`${staticTexts?.about_customers_number}`)}
                                             duration={1} delay={4} easing="linear"/>
                                    +
                                </div>
                            );
                        }}</VisibilitySensor>

                        <small>{staticTexts?.homepage_about_prof_count}</small>
                    </div>
                </div>

                <div className="question-wrap">
                    <div className="question-wrap__question">{staticTexts?.homepage_about_question}</div>
                    <PrimaryButton title={staticTexts?.homepage_about_question_button}
                                   link={getUrlWithLocal("/contact")}/>
                </div>
            </div>
        </div>
        <div className="sec-title">{staticTexts?.homepage_about_sub_title}</div>
        <div className={'sec-block-wrapper'}>
            {
                projects?.map((item, index) => {
                    // return <Project project={item}
                    //                 key={item.id}
                    //                 staticTexts={staticTexts}
                    //                 reverse={index % 2 !== 0}/>
                    return <Link to={getUrlWithLocal(`/buildings/${item?.slug}`)} className={'sec-block-item'} key={index}>
                        <div className={'img_wrapper'}>
                            <img src={generateSliderMediaUrl(item?.mediaSecondary?.path)}
                                 className={'project-img'}
                                 alt=""/>
                        </div>
                        <div className="project-title">{item?.title}</div>
                    </Link>
                })
            }
        </div>
        {playerOpen && <div className={'about-video-player-modal'}>
            <div className={'close-button'} onClick={togglePlayer}>
                <PlayerCloseIcon title={''}/>
            </div>
            <div className={'player-bg'}/>
            <div className={'about-player'}>
                <Player
                    playsInline={true}
                    autoPlay={true}
                    // muted={true}
                    // fluid={false}
                    // width={"100%"}
                    // height={"100%"}
                    // poster={generateFileMediaUrl(aboutSlider?.[0]?.mediaMain?.path)}
                    src={generateFileMediaUrl(about?.mediaVideo?.path)}
                    preload={'auto'}
                >
                    <LoadingSpinner />
                </Player>
            </div>
        </div>}

        <MapWindow projects={artGroupProject}/>
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'aboutSlider',
    'about',
    'projects',
    'contact',
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
