// import packages
import React, {useEffect, useRef, useState} from "react";

// Import assets
import "./homepage.scss"

// Import Components
import Contact from "./contact/Contact";
import MainSlider from "./slider/MainSlider";
import About from "./about/About";
import Futures from "./futures/Futures";
import {CalculatorPanelIcon, ClearIcon, FilterIcon, FilterPanelIcon} from "../../assets/images";
import Partners from "./partners/Partners";
import HomeSlider from "./slider/HomeSlider";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {history} from "../../configs/history";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";

function Homepage({areaData, staticTexts}) {
    //Ref
    const mainRef = useRef(null);
    const buttonRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [show, setShow] = useState(false)
    const showSliderProjects = true// settings?.showSliderProjects


    useEffect(() => {
        if(window.innerWidth <= 1050){
            setShow(true)
        }else {
            window?.addEventListener("scroll", () => {
                setShow(window?.scrollY > mainRef?.current?.clientHeight)
            });
        }

        return ()=>{
            if(window.innerWidth > 1050){
                window?.removeEventListener("scroll",()=>{})
            }
        }
    }, [])

    useEffect(() => {
       if(open){
           document.body.classList.add('modal-open');
       }else{
           document.body.classList.remove('modal-open')
       }
    }, [open])


    return <div className={'Homepage'}>
        {show && <>
            {window.innerWidth <= 1050 ? <div className="search-panel-mobile"
                                        ref={buttonRef}>
                    <div className={'panel_filter_button'} onClick={() => setOpen(!open)}>
                        <FilterPanelIcon/>
                    </div>
                    <div className={'panel_filter_button'} onClick={()=>{
                        history.push(getUrlWithLocal(`/calculator`))
                    }}>
                        <CalculatorPanelIcon/>
                    </div>
                    <div className={'search_from_panel'}
                         onClick={()=>{
                             const event = new CustomEvent('redirect-filter-page-from-panel', );
                             document.dispatchEvent(event);
                         }}>
                        {staticTexts?.homepage_slider_button_houses.replace('%d', areaData?.count || 0)}
                    </div>
                </div> :
                <button className="search-panel-button"
                        ref={buttonRef}
                        onClick={() => setOpen(!open)}>
                    {
                        !open ? <FilterIcon/> : <ClearIcon/>
                    }
                </button>
            }
        </>}
        <div className={'main_slider_wrapper'}>
            <HomeSlider
                refProp={mainRef}
                searchBoxIsSticky={show}
                stickyRowIsOpen={open}
                toggleStickyRow={setOpen}
            />
            {/*{*/}
            {/*    showSliderProjects ? <MainSlider // projects slider*/}
            {/*        refProp={mainRef}*/}
            {/*        searchBoxIsSticky={show}*/}
            {/*        stickyRowIsOpen={open}*/}
            {/*        toggleStickyRow={setOpen}*/}
            {/*    /> : */}
            {/*}*/}
        </div>
        <About/>
        <Futures/>
        <Contact/>
        <Partners/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'areaData',

    ])
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
