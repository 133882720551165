// import packages
import React, {useEffect, useState} from "react";
import {YMaps, Map, Placemark, Polygon, ObjectManager} from 'react-yandex-maps';

// Import assets
import "./mapWindow.scss"
import {Logo, MapMinusIcon, MapPlusIcon, PlusIcon} from "../../../assets/images";

//Utils
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";
import { history } from "../../../configs/history";
import { getUrlWithLocal } from "../../../utils/getUrlWithLocal";
import { toggleMapWindow } from "../../../utils/toggleMapWindow";
import queryString from 'query-string'
import { useLocation } from "react-router-dom";


function MapWindow({projects}) {
    const [zoom, setZoom] = useState(10)
    const [ymap, setYmap] = useState(null)
    const [loaded, setLoaded] = useState(false)
    // console.log(ymap, 'ymap');
    const location = useLocation()
    const queryParams = queryString.parse(location.search)

    const showMap = queryParams.map
    useEffect(() => {
        setZoom(12)
    }, [showMap, ymap])

    useEffect(() => {
        setLoaded(true)
    }, [ymap])

    const handleClick = (item) => {
        item && history.push(getUrlWithLocal(`/buildings/${item?.slug}`))
        toggleMapWindow()
    };

    function createChipsLayout (item) {
        const src = generateFileMediaUrl(item?.mediaMain?.path)

        const MyLayoutClass = ymap?.templateLayoutFactory.createClass(
            '<div class="icon-content">' +
            '<div class="marker"><img class="marker-img" src=' + src + ' alt=""></div>' +
            '<div class="marker-title">' + item?.title + '</div>' +
            '</div>', {
                // Define the methods for handling the click and mouseover events
                build: function() {
                    MyLayoutClass.superclass.build.call(this);
                    var map = this.getData().geoObject.getMap();

                    let options = this.getData().options;
                    let circleShape = { type: 'Circle', coordinates: [0, -75], radius: 41, fillColor: "#00FF00",  };
                    let rectangleShape = { type: 'Rectangle', coordinates: [[-85, -45], [85, -22]] };
                    options.set('shape', circleShape);
                },

            }
        );
        return MyLayoutClass
    }

    return !!showMap && <div className={`map_window ${showMap ? 'show-map' : ''}`}>
        <div className="map_window__header">
            <img src={Logo} alt=""/>
            <div className={`close`} onClick={toggleMapWindow}/>
        </div>
        <div className="map_window__zoom">
            <button onClick={() => {
                setZoom(zoom + 1)
            }}><MapPlusIcon/></button>
            <button onClick={() => {
                setZoom(zoom > 2 ? (zoom - 1) : zoom)
            }}><MapMinusIcon/></button>
        </div>

        {
           <YMaps query={{
                apikey: 'eacc125a-6e5b-4983-a845-721586b10f68',
                // lang: `${selectedLanguage?.code}_${selectedLanguage?.code?.toUpperCase()}`
            }}
            >
                <Map
                    state={{
                        center: [40.214033, 44.552007],
                        zoom: zoom || 'MAP_DEFAULT_ZOOM',
                        hue: "ff0000",
                    }}
                    onLoad={(ympasInstance) => {
                        setYmap(ympasInstance)
                    }}
                    options={{
                        minZoom: 5
                    }}
                    modules={["Placemark", "geocode", "geoObject.addon.balloon", "templateLayoutFactory", "layout.ImageWithContent"]}
                    width={'100%'} height={'100%'}
                >
                    {
                        loaded &&  !!projects?.length && projects?.map(item => {
                            const src = generateFileMediaUrl(item?.mediaMain?.path)
                            const circleLayout = ymap && ymap?.templateLayoutFactory?.createClass('<div class="icon-content">' +
                                '<div class="marker"><img class="marker-img" src=' + src + ' alt=""></div>' +
                                '<div class="marker-title">' + item?.title + '</div>' +
                                '</div>');
                            return <Placemark
                                onClick={handleClick.bind(null, item)}
                                geometry={[item?.latitude, item?.longitude]}
                                key={item.id}
                                // options={{
                                //     iconLayout: circleLayout,
                                // }}
                                options={{
                                    iconLayout: createChipsLayout(item)
                                }}
                            />
                        })
                    }
                    <Polygon
                        // instanceRef={(ref) => ref && draw(ref)}
                        geometry={[ [projects?.[0]?.latitude, projects?.[0]?.longitude],
                            [53.3671486, 77.889309],]}
                        options={{
                            editorDrawingCursor: "crosshair",
                            editorMaxPoints: 5,

                            fillColor: "#00FF00",
                            // Цвет обводки.
                            strokeColor: "#0000FF",
                            // Ширина обводки.
                            strokeWidth: 5
                        }}
                    />
                             <ObjectManager
                        options={{
                            editorDrawingCursor: "crosshair",
                            editorMaxPoints: 5,

                            fillColor: "#00FF00",
                            // Цвет обводки.
                            strokeColor: "#0000FF",
                            // Ширина обводки.
                            strokeWidth: 5
                        }}
                    />
                </Map>
            </YMaps>
        }
    </div>
}

export default MapWindow;
