export function maskInputPhoneNumber(value, prevValue) {
    let phoneInput = value?.replace(/\D/g, "") || "";


    if (prevValue?.length > value?.length && phoneInput?.length === 1 && phoneInput[0] === '3') {
        return '';
    } else {
        const numberLength = 8;
        let result;

        if (phoneInput.startsWith("374")) {
            result = "+374 ("
            phoneInput = phoneInput.slice(3);
        } else {
            result = "+374 (";
            phoneInput = phoneInput.replace(/^3?/, "");
        }

        for (let i = 0; i < phoneInput.length && i < numberLength; i++) {
            switch (i) {
                case 2:
                    result += ") ";
                    break;
                case 4:
                    result += "-";
                    break;
                case 6:
                    result += "-";
                    break;
                default:
                    break;
            }
            result += phoneInput[i];
        }

        return result;
    }
}
