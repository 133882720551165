// Import packages
import React, {useEffect, useState} from "react";
import {useLocation, withRouter} from 'react-router-dom';
import {connect} from "react-redux";

// Import assets
import '../assets/styles/layout.scss'

// Import components
import Header from "../components/Header";
import Footer from "../components/Footer";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetSettings,
    GetLanguages,
    GetStaticTexts,
    GetMenu,
    GetAbout,
    GetMainSlider,
    GetAboutSlider,
    GetContact,
    GetBadges,
    GetBanks,
    GetProjects,
    GetAreasInitialInfo, GetPartners,
} from "../redux/actions";
import MetaTags from "../components/MetaTags";
import { CallIcon, CallV2Icon } from "../assets/images";
import CallModal from "../components/modal/callModal/CallModal";
import HomeHeader from "../components/HomeHeader";

function Layout(props) {
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
    const [visible, setVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        props.GetSettings()
        props.GetLanguages()
        props.GetStaticTexts()
        props.GetMenu()
        props.GetMainSlider().finally(() => {
            props.GetAreasInitialInfo()
            props.GetAbout()
            props.GetAboutSlider()
            props.GetContact()
            props.GetBadges()
            props.GetBanks()
            props.GetProjects()
            props.GetPartners()
        })
    }, [props.selectedLanguage?.id]);


    const toggleMobileMenu = () => {
        setMobileMenuIsOpen(!mobileMenuIsOpen)
    };

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const {children, contact} = props;
    const showFooter = !location.pathname.includes('filter')
    const inHomePage = location.pathname.includes('home')

    return <div className={`LayoutWrapper ${mobileMenuIsOpen ? "mobileMenuOpen" : ""}`}>
        <MetaTags/>
        {(window.innerWidth <= 1050  && inHomePage )?
            <a href={`tel:${contact?.phoneNumber}`}
               className="call_btn_v2"
               // onClick={toggleVisibility}
            >
                <CallV2Icon/>
            </a>:
            <button className="call-btn" onClick={toggleVisibility}>
            <CallIcon/>
        </button>}
        <HomeHeader mobileMenuIsOpen={mobileMenuIsOpen}
                                 toggleMobileMenu={toggleMobileMenu}/>
               {/*<Header mobileMenuIsOpen={mobileMenuIsOpen}
                        toggleMobileMenu={toggleMobileMenu}/>*/}

        <div className={`MainContent ${inHomePage ? 'homeContent' : ''}`}>
            {children}
        </div>
        {showFooter && <Footer/>}
        <CallModal visible={visible}
                   toggleVisibility={toggleVisibility}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'languages',
        'contact',
        'selectedLanguage'
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetSettings,
    GetStaticTexts,
    GetMenu,
    GetAbout,
    GetMainSlider,
    GetAboutSlider,
    GetContact,
    GetBadges,
    GetBanks,
    GetProjects,
    GetAreasInitialInfo,
    GetPartners,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
