// import packages
import React, {useState} from "react";
import {connect} from 'react-redux';
import {Link, useLocation} from "react-router-dom";

//import styles
import "../assets/styles/components/header.scss";
import {Logo, PhoneIcon} from "../assets/images"

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {history} from "../configs/history";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";

// Import components
import {Dropdown, Menu} from "antd";
import {ChangeLanguage} from "../redux/actions";
import RightMenu from "./menu/RightMenu";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

function Header(props) {
    const { contact, staticTexts, selectedLanguage, languages} = props;
    const [openMenu, setOpenMenu] = useState(false)

    const location = useLocation()

    function toggleMenu() {
        setOpenMenu(!openMenu)
    }

    const phone = contact?.phoneNumber?.replace(/\D/g, '').substring(0, 12)
    const areaCode = phone?.substring(0, 3);
    const code = phone?.substring(3, 5);
    const content = phone?.substring(5, 12);

    const menuLanguage = !!languages?.length && (
        <Menu className="languages-dropdown">
            {!!languages?.length && languages?.map((item) => {
                return selectedLanguage?.code !== item.code &&
                    <Menu.Item key={item.id}
                               onClick={() => {
                                   history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                   props.ChangeLanguage(item)
                               }}
                               className={'language-dropdown-item'}>
                        <img src={generateImageMediaUrl(item?.iconPath?.path)} alt=""/>
                        <div>{item.name}</div>
                    </Menu.Item>
            })
            }
        </Menu>
    );

    return <div className={`headerWrapper ${openMenu ? 'fullscreen-menu' : ''} ${location.pathname.includes('/home') ? '' : 'dark'}`}>
        <div className="menu-wrapper">
            <div className="left-side">
                <Link to={getUrlWithLocal("/home")} className="logo-wrapper">
                    {/*<img src={Logo} alt=""/>*/}
                    <Logo/>
                </Link>
            </div>
            <div className="right-side">
                <a href={`tel:${contact?.phoneNumber}`}>
                    <div className="header-phone">
                        <span>{`+${areaCode} (${code}) ${content}`}</span>
                        <div className={'header-phone__icon'}>
                            <PhoneIcon/>
                        </div>
                    </div>
                </a>
                <Dropdown overlay={menuLanguage}
                          trigger={['click']}
                          overlayClassName={'languages-wrapper'}
                          placement="bottomLeft"
                          className={'language-menu'}
                          size={'small'}>
                    <div className="dropdown-menu-item">
                        <img src={generateImageMediaUrl(selectedLanguage?.iconPath?.path)} alt=""/>
                    </div>
                </Dropdown>
                <div onClick={toggleMenu} className={`headerWrapper__burger_wrapper`}>
                    <div className={`headerWrapper__burger`}/>
                </div>
            </div>
        </div>
        <RightMenu openMenu={openMenu} toggleMenu={toggleMenu} className={openMenu ? 'expand_on' : ''}/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contact',
        'languages',
        'selectedLanguage'
    ])
};

const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
