// import packages
import React, {useRef, useState} from "react";
import Slider from "react-slick";

// Import assets
import "./partners.scss"
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";

// Import Components

// Import utils


function Partners(props){
    const {staticTexts,partners} = props
    const [hover, setHover] = useState(false)

    // console.log(partners,'partners')

    let sliderRef = useRef(null);
    const settings = {
        dots: false,
        arrows: false,
        autoplay:true,
        infinite:true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        ref: partner => (sliderRef = partner),
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 3,
                }
            },
        ]
    };

    return <div className={'partners-wrapper'}>
        {
            partners?.length ? <div className={'partners-title'}>
                {staticTexts?.homepage_partner_title}
            </div> : ''
        }
        <div className={'partners-block'}>
            <Slider {...settings}>
                {partners?.map(item => {
                    return <div className="partner-item" key={item.id} >
                        <div className="partner-content">
                            <img src={generateFileMediaUrl(item?.hoverIcon?.path)} className="fade-image"
                                 title={item?.hoverIcon?.title ? item?.hoverIcon?.title : ''}
                                 alt={item?.hoverIcon?.altAttribute ? item?.hoverIcon?.altAttribute : ''}/>

                            <img src={generateFileMediaUrl(item?.icon?.path)}
                                 className="hover-image"
                                 title={item?.icon?.title ? item?.icon?.title : ''}
                                 alt={item?.icon?.altAttribute ? item?.icon?.altAttribute : ''}/>
                        </div>
                    </div>
                })}
            </Slider>
        </div>
    </div>
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'partners'
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Partners);