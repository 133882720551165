// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

// Import assets
import './right-menu.scss';

// Import components
import {Dropdown, Menu} from "antd";
import {ClearIcon} from "../../assets/images"

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {ChangeLanguage} from "../../redux/actions";
import {history} from "../../configs/history";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";



const RightMenu = memo((props) => {
    const {className, staticTexts, toggleMenu,languages, toggleLanguage,selectedLanguage, projects, menu} = props;
    function navigate(path) {
        history.push(getUrlWithLocal(`${path}`))
        toggleMenu()
    }

    console.log(menu,'menu')

    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <nav>
                <ClearIcon className='clear-icon' onClick={toggleMenu}/>
                <div onClick={()=> navigate(`/about`)} className="nav-item">{staticTexts?.menu_about}</div>
                <div onClick={()=> navigate(`/contact`)} className="nav-item">{staticTexts?.menu_contact}</div>
                <div onClick={()=> navigate(`/filter`)} className="nav-item">{staticTexts?.menu_apartments}</div>
                <div onClick={()=> navigate(`/calculator`)} className="nav-item">{staticTexts?.menu_calculator}</div>
           {/*     <div onClick={()=> navigate(`/custom-page/construction-management`)} className="nav-item">{staticTexts?.header_construction}</div>*/}
                {menu && !!menu?.length && menu.map(item=>{
                    return <div onClick={()=> navigate(`/custom-page/${item.pageSlug}`)} className="nav-item">{item.title}</div>
                })}
                <div onClick={()=> window.open(`https://app.art-group.am/admin/files/financial-statement/Report-2023.pdf` , '_blank')} className="nav-item">{staticTexts?.menu_reports}</div>

                {
                    !!projects?.length && projects?.map(item => {
                        return <div onClick={()=> navigate(`/buildings/${item?.slug}`)}
                                    className="nav-item" key={item.id}>
                            {item?.title}
                        </div>
                    })
                }
            </nav>

            <div className="menu-languages">
                {!!languages?.length && languages?.map((item) => {
                    return <div className={`language ${selectedLanguage?.code === item.code ? 'active' : ''}`}
                                key={item.id}
                                onClick={() => {
                                    history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                    props.ChangeLanguage(item)
                                }}>
                        {item.name}
                    </div>
                })
                }
            </div>
        </div>
        <div className={`menu-overlay`} onClick={toggleMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'languages',
        'selectedLanguage',
        'projects',
        'menu'
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
