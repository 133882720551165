// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {LoadingOutlined} from '@ant-design/icons'
import {isEmail} from "validator";

// Import assets
import "./contact.scss"
import {TickIcon} from "../../../assets/images";

// Import Components
import {InputGroup} from "../../../components/ui-elements/input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {AddSubscribers} from "../../../redux/actions";
import {Link} from "react-router-dom";
import {maskInputPhoneNumber} from "../../../utils/maskInputPhoneNumber";


function Contact(props) {
    const [tick, setTick] = useState(false)
    const [fieldsData, setFieldsData] = useState({
        fullName:'',
        email:'',
        phoneNumber:'',
    })
    const [errors, setErrors] = useState({
        fullName:false,
        email:false,
        phoneNumber:false,
    })


    function getInputValues(e) {
        let {name, value} = e.target
        // if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
        //     return
        // }
        if (name === "phoneNumber") {
            value = maskInputPhoneNumber(value, fieldsData.phoneNumber || "")
        }

        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email))
            ) {
                errors[key] = true;
                result = false;
            }

        })
        console.log(errors,'errors')
        setErrors(errors);
        return result;
    }

    function addSubscribers() {
        if (validate()) {
            props.AddSubscribers(fieldsData).then(() => {
                setFieldsData({
                    fullName:'',
                    email:'',
                    phoneNumber:'',
                })
            })
        } else {
        }
    }

    console.log(errors,'126753617')
    const {requestLoading, staticTexts} = props

    return <div className={'HomepageContact'}>
        <div className={'HomepageContactBlock'}>
            <div className="LeftSide">
                <h1 className={'title'}>{staticTexts?.homepage_contact_title}</h1>
                <div className={'description'}>{staticTexts?.homepage_contact_text}
                </div>
            </div>
            <div className="RightSide">
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.fullName}
                    error={errors.fullName}
                    name={'fullName'}
                    maxLength={100}
                    placeholder={staticTexts?.contacts_page_placeholder_name}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.phoneNumber}
                    error={errors.phoneNumber}
                    name={'phoneNumber'}
                    maxLength={30}
                    placeholder={staticTexts?.contacts_page_placeholder_phoneNumber}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.email}
                    error={errors.email}
                    name={'email'}
                    placeholder={staticTexts?.homepage_contact_placeholder_email}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <button className='PrimaryButton bordered'
                        onClick={() => {
                            if (!requestLoading && addSubscribers) {
                                addSubscribers();
                            }
                        }}
                >
                    {staticTexts?.homepage_contact_button_send}
                    {
                        requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                            : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                    }

                </button>
            </div>
            <div className={'BottomBlock'}>
                {staticTexts?.homepage_contact_block_text}
                <Link to={'/privacy-policy'}> {staticTexts?.homepage_contact_block_link_text}</Link>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'requestLoading'
]);

const mapDispatchToProps = {
    AddSubscribers
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
