import { GENERAL_CONSTS } from "../constants";
import { StaticTexts } from "../../constants/staticTexts";
import { history } from "../../configs/history";

export const initialState = {
    settings: {},
    languages: [],
    selectedLanguage: '',
    staticTexts: StaticTexts.hy,
    priceRegExp: '',
    menu: [],
    badges: [],
    banksList: [],
    customPages: {},
    projects: [],
    partners: [],
    projectById: null,
    areaData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                && action.payload.find(lg => lg.id === state.selectedLanguage.id);
            let newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);
            const pathCode = history.location?.pathname?.split('/')?.[1]
            if(pathCode && action.payload.find(lg => lg.code === pathCode)){
                newSelectedLanguage = action.payload.find(lg => lg.code === pathCode)
            }
            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };

            if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
                newState.translation = state.staticTexts[newSelectedLanguage.code]
            }
            return newState;

        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };

        case GENERAL_CONSTS.GET_SETTINGS:
            return {
                ...state,
                settings: action.payload,
            };

        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code] || StaticTexts[state.selectedLanguage?.code],
            };

        case GENERAL_CONSTS.GET_MENU:
            return {
                ...state,
                menu: action.payload,
            };
        case GENERAL_CONSTS.GET_BADGES:
            return {
                ...state,
                badges: action.payload,
            };
        case GENERAL_CONSTS.GET_BANKS:
            return {
                ...state,
                banksList: action.payload,
            };
        case GENERAL_CONSTS.GET_CUSTOM_PAGES:
            return {
                ...state,
                customPages: action.payload,
            };
        case GENERAL_CONSTS.GET_PROJECTS:
            return {
                ...state,
                projects: action.payload,
            };
        case GENERAL_CONSTS.GET_PROJECT_BY_ID:
            return {
                ...state,
                projectById: action.payload,
            };
        case GENERAL_CONSTS.CLEAR_PROJECTS_BY_ID:
            return {
                ...state,
                projectById: null,
            };
        case GENERAL_CONSTS.GET_AREAS_INFO:
            return {
                ...state,
                areaData: action.payload,
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload,
            };

        default:
            return state;
    }
}
